module.exports = {
  defaultTitle: 'L-Tavarez',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Luis A.Tavarez',
  url: 'https://l-tavarez.site',
  img:'https://camo.githubusercontent.com/410dd0b1b800cd1e13965237beee2a32474be978/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f4d3967624264396e6244724f5475314d71782f67697068792e676966',
  legalName: 'Luis A.Tavarez',
  defaultDescription: 'I’m Luis A.Tavarez and I’m a Full-Stack Developer!',
  socialLinks: {
    // twitter: 'http://www.twitter.com/smakosh',
    github: 'https://github.com/uppy19d0',
    linkedin: 'https://www.linkedin.com/in/luis-a-tavarez-a2400b174/',
    // instagram: 'https://instagram.com/smakosh19',
    // youtube: 'https://www.youtube.com/user/smakoshthegamer',
    // google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  // googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    // facebook: 'appId',
    // twitter: '@smakosh',
  },
  address: {
    city: 'Santo Domingo Este',
    region: 'Santo Domingo',
    country: 'Dominican Republic',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'luis_aneuris16@hotmail.com',
    phone: 'phone number',
  },
  foundingDate: '2021',
};
